<template>
  <span v-if="isAllowed">
    <v-btn
      icon
      x-small
      :class="item.approved ? 'green' : ''"
      :outlined="!item.approved"
      @click="handleClickApproval"
    >
      <v-icon x-small>mdi-check-bold</v-icon>
    </v-btn>
    <v-btn
      class="ml-1"
      color="red"
      outlined
      icon
      x-small
      @click="handleClickRemove"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </span>
</template>

<script>
import { mapGetters } from "vuex";

import bus from "@/bus";
import { axios } from "@/plugins/axios";
import VueTip from "@/components/VueTip";
import LatexHtml from "@/components/LatexHtml";

export default {
  props: ["item", "uri"],
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
    isAllowed() {
      if (!this.authUser || !this.authUser.id) {
        return false;
      }

      if (
        ["admin", "superadmin", "manager", "moderator"].includes(
          this.authUser.role
        )
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    item(val) {
      if (!val) {
        //
      }
    },
  },
  methods: {
    handleClickRemove() {
      this.doRemove();
    },
    handleClickApproval() {
      this.doApproval();
    },
    doRemove() {
      if (!this.item) {
        console.log("item not avaiable", this.item);
        return;
      }
      if (!this.uri) {
        console.log("uri not avaiable", this.uri);
        return;
      }
      if (
        !confirm(
          `Are you sure to delete ${
            this.item.title
              ? this.item.title
              : this.item.text
              ? this.item.text
              : this.item.id
          }?`
        )
      ) {
        return Promise.reject("CANCELED_BY_USER");
      }

      bus.$emit("showWait");
      return axios
        .delete("/" + this.uri + "/" + this.item.id)
        .then((res) => {
          this.$emit("removed", this.item);
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          this.loading = false;
          bus.$emit("hideWait");
        });
    },
    doApproval() {
      if (!this.item) {
        console.log("item not avaiable", this.item);
        return;
      }
      if (!this.uri) {
        console.log("uri not avaiable", this.uri);
        return;
      }

      this.loading = true;
      bus.$emit("showWait");

      return axios
        .get("/" + this.uri + "/approved/" + this.item.id)
        .then((res) => {
          this.$emit("approved", res.data);
          if (res.data) {
            this.item.approved = res.data.approved;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
          bus.$emit("hideWait");
        });
    },
  },
};
</script>

<style>
</style>