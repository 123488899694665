var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items && _vm.authUser && _vm.authUser.id)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',{staticClass:"blue accent-3 white--text"},[_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({ name: 'home' })}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-spacer'),_vm._v(" "+_vm._s(_vm.$lang("doubts"))+" ")],1)],1)],1),_vm._l((_vm.items),function(item,i){return _c('v-col',{key:i,attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"mx-0 rounded-lg",attrs:{"elevation":"0","outlined":""}},[_c('v-card-actions',[_c('moderator-actions',{staticClass:"mr-2",attrs:{"item":item,"uri":"doubts"},on:{"removed":function (itm) { return (_vm.items = _vm.items.filter(function (el) { return el.id != itm.id; })); }}}),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm._f("formatDateOnly")(item.created_at))+" | "+_vm._s(item.user.display_name)+" ")]),_c('v-spacer'),(item.resolved)?_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$lang('Resolved')),expression:"$lang('Resolved')"}],attrs:{"small":""}},[_vm._v("mdi-check")]):_vm._e(),(!item.approved && item.user_id == _vm.authUser.id)?_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$lang('PENDINGAPPROVAL')),expression:"$lang('PENDINGAPPROVAL')"}],attrs:{"small":"","color":"orange"}},[_vm._v(" mdi-exclamation ")]):_vm._e(),(item.user_id == _vm.authUser.id)?_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$lang('Remove')),expression:"$lang('Remove')"}],attrs:{"color":"red","small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleRemove(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1):_vm._e()],1),_c('div',{staticStyle:{"max-height":"280px","overflow":"hidden"},on:{"click":function () {
            _vm.$router.push({
              name: 'doubt',
              params: {
                id: item.id,
              },
            });
          }}},[_c('v-divider'),_c('v-card-text',[_c('latex-html',{staticClass:"subtitle-1",attrs:{"html":item.title}}),_c('latex-html',{staticClass:"body",attrs:{"html":item.description}})],1),(item.image_url && item.image_url.length > 0)?_c('v-card-text',[_c('v-img',{attrs:{"contain":true,"src":item.image_url}})],1):_vm._e()],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-up")]),_vm._v(" 0 ")],1),_c('v-btn',{attrs:{"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-down")]),_vm._v(" 0 ")],1),_c('v-spacer'),_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$lang('TotalAnswersIncludingUnapproved')),expression:"$lang('TotalAnswersIncludingUnapproved')"}],attrs:{"text":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-comment")]),_vm._v(" "+_vm._s(item.__meta__ ? item.__meta__.answers_count : 0)+" ")],1)],1)],1)],1)}),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{attrs:{"id":"scrolltothis"}},[_vm._v(" ")]),_c('query-page-redirect',{attrs:{"pagination":_vm.pagination}})],1),_c('fab-button',{attrs:{"icon":"mdi-plus","color":"primary white--text"},on:{"clicked":_vm.handleFabClick}}),_c('new-doubt-dialog',{attrs:{"show":_vm.showNewDialog},on:{"closed":function (val) { return (_vm.showNewDialog = val); },"submitted":_vm.handleNewSubmit}}),(_vm.items.length < 1)?_c('v-col',[_c('v-card-title',{staticClass:"justify-center"},[_vm._v(" "+_vm._s(_vm.$lang("AskAQuestion"))+" ")])],1):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }