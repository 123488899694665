<template>
  <v-row v-if="items && authUser && authUser.id">
    <v-col cols="12">
      <v-card>
        <v-card-title class="blue accent-3 white--text">
          <v-btn dark icon @click.stop="$router.push({ name: 'home' })">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          {{ $lang("doubts") }}
        </v-card-title>
      </v-card>
    </v-col>
    <v-col v-for="(item, i) in items" :key="i" cols="12" md="6">
      <v-card class="mx-0 rounded-lg" elevation="0" outlined>
        <v-card-actions>
          <moderator-actions
            @removed="(itm) => (items = items.filter((el) => el.id != itm.id))"
            :item="item"
            uri="doubts"
            class="mr-2"
          />
          <div class="caption">
            {{ item.created_at | formatDateOnly }} |
            {{ item.user.display_name }}
          </div>
          <v-spacer></v-spacer>
          <v-icon v-tooltip="$lang('Resolved')" small v-if="item.resolved"
            >mdi-check</v-icon
          >
          <v-icon
            v-tooltip="$lang('PENDINGAPPROVAL')"
            small
            color="orange"
            v-if="!item.approved && item.user_id == authUser.id"
          >
            mdi-exclamation
          </v-icon>
          <v-btn
            v-tooltip="$lang('Remove')"
            v-if="item.user_id == authUser.id"
            color="red"
            @click.stop="handleRemove(item)"
            small
            icon
          >
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <div
          @click="
            () => {
              $router.push({
                name: 'doubt',
                params: {
                  id: item.id,
                },
              });
            }
          "
          style="max-height: 280px; overflow: hidden"
        >
          <v-divider></v-divider>
          <v-card-text>
            <latex-html class="subtitle-1" :html="item.title" />
            <latex-html class="body" :html="item.description" />
          </v-card-text>
          <v-card-text v-if="item.image_url && item.image_url.length > 0">
            <v-img :contain="true" :src="item.image_url"></v-img>
          </v-card-text>
        </div>
        <v-card-actions>
          <v-btn text>
            <v-icon left>mdi-arrow-up</v-icon>
            0
          </v-btn>

          <v-btn text>
            <v-icon left>mdi-arrow-down</v-icon>
            0
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn v-tooltip="$lang('TotalAnswersIncludingUnapproved')" text>
            <v-icon left small>mdi-comment</v-icon>
            {{ item.__meta__ ? item.__meta__.answers_count : 0 }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12">
      <div id="scrolltothis">&nbsp;</div>
      <query-page-redirect :pagination="pagination" />
    </v-col>
    <fab-button
      @clicked="handleFabClick"
      icon="mdi-plus"
      color="primary white--text"
    />
    <new-doubt-dialog
      :show="showNewDialog"
      @closed="(val) => (showNewDialog = val)"
      @submitted="handleNewSubmit"
    />
    <v-col v-if="items.length < 1">
      <v-card-title class="justify-center">
        {{ $lang("AskAQuestion") }}
      </v-card-title>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

import bus from "@/bus";
import { axios } from "@/plugins/axios";
import QueryPageRedirect from "@/components/QueryPageRedirect";
import FabButton from "@/components/FabButton";
import NewDoubtDialog from "./NewDoubtDialog";
import LatexHtml from "@/components/LatexHtml";
import { scrollToTargetAdjusted } from "@/helpers/utils";
import ModeratorActions from "../modules/ModeratorActions";

export default {
  name: "doubts",
  components: {
    QueryPageRedirect,
    FabButton,
    NewDoubtDialog,
    LatexHtml,
    ModeratorActions,
  },

  data() {
    return {
      items: [],
      loading: true,
      showNewDialog: null,
      pagination: {
        lastPage: null,
        page: 1,
        perPage: null,
        total: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
  },
  methods: {
    handleRemove(item) {
      if (
        !confirm(`Are you sure to delete ${item.title ? item.title : item.id}?`)
      ) {
        return Promise.reject("CANCELED_BY_USER");
      }
      bus.$emit("showWait");
      return axios
        .delete("doubts/" + item.id)
        .then((res) => {
          this.items = this.items.filter((el) => {
            return el.id != item.id;
          });
        })
        .catch((err) => {
          if (err.response.status == 404) {
            this.items = this.items.filter((el) => el.id != item.id);
          }
          console.log("err", err.response);
        })
        .finally(() => {
          this.loading = false;
          bus.$emit("hideWait");
        });
    },
    handleNewSubmit(data) {
      this.fetchItems(this.$route.query.page);
    },
    handleFabClick() {
      this.showNewDialog = true;
      console.log("fab clicked");
    },
    fetchItems(page = 1) {
      this.loading = true;
      bus.$emit("showWait");

      axios
        .get("doubts/mine/and/approved?orderby=id|desc&perPage=30&page=" + page)
        .then((res) => {
          //this.items = res.data.data;
          // will get latest 30 entries and reverse them
          this.items = res.data.data.sort((a, b) =>
            a.id > b.id ? 1 : b.id > a.id ? -1 : 0
          );
          this.pagination = {
            lastPage: res.data.lastPage,
            page: res.data.page,
            perPage: res.data.perPage,
            total: res.data.total,
          };
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;

          bus.$emit("hideWait");
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 400);
        });
    },
  },
  updated() {
    if (!this.showNewDialog) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  },
  created() {
    this.fetchItems(this.$route.query.page);
  },
};
</script>

<style>
</style>